import { MetricsPerCoverageType } from '@@core/models/build/metrics.model';

export const ENTIRE_BUILD = 'Entire Build';
export const ALL_TEST_STAGES = ' All test stages';

export interface TestStageTestCounts {
	total: number;
	passed: number;
	failed: number;
	skipped: number;
	regression: number;
}

export interface TestStageMetrics {
	testStage: string;
	qrs: MetricsPerCoverageType;
	coverage: MetricsPerCoverageType;
	modifiedCodeCoverage: MetricsPerCoverageType;
}

export enum TestStageStatus {
	RUNNING = 'running',
	ENDED = 'ended'
}

export enum CoverageCalculationStatus {
	pending = 'pending',
	processing = 'processing',
	calculating = 'calculating',
	ready = 'ready'
}

export interface TestStage {
	buildSessionId: string;
	customerId: string;
	appName: string;
	branchName: string;
	buildName: string;
	testStage: string;
	testCount: TestStageTestCounts;
	testsDuration: number;
	qualityStatus: string;
	metrics: TestStageMetrics;
	isEntireBuild: boolean;
	status?: {
		testStageStatus: TestStageStatus;
		timestamp: number;
		txId: string;
	};
	coverageCalculationStatus?: CoverageCalculationStatus;
	lastCoverageCalculationStatusTime?: number;
	tsrCount?: number;
}
